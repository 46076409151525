import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import ContentCardFooter from '../../common/template/contentCardFooter';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import Select from '../../common/form/select';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../../common/form/labelAndCheckbox';
import { setError } from '../../common/toast/toast';
import consts from '../../consts';
import imagemAdd from '../../assets/images/icone_add_imagem.png';
import pdfAdd from '../../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './produtoModeloActions';

class ProdutoModeloForm extends Component {

    componentWillMount() {

		let initSeletorImagem = () => {
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.imagens || []).map(item => item);
						lista.push({
							imagem_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							imagens: lista
						});

						initSeletorImagem();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelector = fileSelector;
		};

		initSeletorImagem();
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>

						<Row style={{ marginTop: 12 }}>
							<Field
								name='feminino'
								component={LabelAndCheckbox}
								label='Feminino' placeholder='Informe'
								cols='6 4 3 2'
								readOnly={readOnly} />

							<Field
								name='masculino'
								component={LabelAndCheckbox}
								label='Masculino' placeholder='Informe'
								cols='6 4 3 2'
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label>Imagens (800 X 800)</label>
							</Grid>
							<Grid cols='12 12 12 12'>

								<Row>									
									{(this.props.formularioValues.imagens || []).map(imagem => (
										<div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 16, paddingBottom: 16 }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={imagem.imagem_base64 ? imagem.imagem_base64 : `${consts.URL}/${imagem.imagem}`} style={{ height: 200 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={imagem.imagem_base64 ?true : false}
													onClick={() => {
														window.open(`${consts.URL}/${imagem.imagem}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															imagens: this.props.formularioValues.imagens.filter(item => item != imagem)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" 
											onClick={() => this.fileSelector.click()}
											style={{ marginLeft: 16 }}>
											<img src={imagemAdd} style={{ height: 200 }} />
										</span>
									) : null}
								</Row>
							</Grid>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar Modelo'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

ProdutoModeloForm = reduxForm({form: 'produtoModeloForm', destroyOnUnmount: false})(ProdutoModeloForm);
const mapStateToProps = state => ({
	sessao: state.auth.produtoModelo,
	formularioValues: getFormValues('produtoModeloForm')(state),
	registro: state.produtoModelo.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoModeloForm);
