const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaRegiao: [] 
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'LOJA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'LOJA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'LOJA_REGIAO_SELECT_LISTADO':
			return {
				...state,
				listaRegiao: action.payload.data
			};

        default:
            return state;
    }
}
