import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import ContentCardBody from '../../common/template/contentCardBody';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import Button from '../../common/button/button';
import Form from './produtoModeloForm';
import FormatUtils from '../../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista } from './produtoModeloActions';

class ProdutoModelo extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista(this.props.id_produto);
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', { id_produto: this.props.id_produto });
									this.props.initForm({ id_produto: this.props.id_produto });
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Modelo</Th>
								<Th alignCenter>Sexo</Th>
								<Th alignCenter>Removido</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome}</Td>
									<Td alignCenter>{item.feminino && item.masculino ? 'Ambos' : (item.feminino ? 'Feminino' : (item.masculino ? 'Masculino' : 'Nenhum'))}</Td>
									<Td alignCenter>
										<input
											type='checkbox'
											checked={item.removido}
											onChange={data => this.props.salvar({ ...item, removido: !item.removido })} />
									</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item
												});
												this.props.initForm({
													...item
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.produtoModelo,
	modoTela: state.produtoModelo.modoTela,
	lista: state.produtoModelo.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoModelo);
