import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import RegiaoReducer from '../regiao/regiaoReducer';
import LojaReducer from '../loja/lojaReducer';
import ProdutoCategoriaReducer from '../produtoCategoria/produtoCategoriaReducer';
import ProdutoReducer from '../produto/produtoReducer';
import ProdutoModeloReducer from '../produto/produtoModelo/produtoModeloReducer';
import PedidoReducer from '../pedido/pedidoReducer';
import PedidoItemReducer from '../pedido/pedidoItem/pedidoItemReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuario: UsuarioReducer,
	regiao: RegiaoReducer,
	loja: LojaReducer,
	produtoCategoria: ProdutoCategoriaReducer,
	produto: ProdutoReducer,
	produtoModelo: ProdutoModeloReducer,
	pedido: PedidoReducer,
	pedidoItem: PedidoItemReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

