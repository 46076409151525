import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import PedidoItem from './pedidoItem/pedidoItem';

import { setModoTela, initForm, getListaItem, salvarItem } from './pedidoActions';

class PedidoForm extends Component {

    componentWillMount() {
		this.props.getListaItem(this.props.formularioValues.id || 0);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='nome'
									component={LabelAndInput}
									label='Nome *' placeholder='Informe o Nome'
									cols='12 12 12 12'
									readOnly={readOnly} />
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>

					</Form>
				</ContentCard>

				{(this.props.formularioValues.id) ? (
					<PedidoItem id_pedido={this.props.formularioValues.id} />
				) : null}
			</>
        )
    }


}

PedidoForm = reduxForm({form: 'pedidoForm', destroyOnUnmount: false})(PedidoForm);
const mapStateToProps = state => ({
	sessao: state.auth.pedido,
	formularioValues: getFormValues('pedidoForm')(state),
	registro: state.pedido.registro,
	listaCategoria: state.pedido.listaCategoria,
	listaItem: state.pedido.listaItem
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaItem, salvarItem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoForm);
