import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

import { carregarUsuario } from '../../auth/authActions';

class Menu extends Component {

	state = {
		dashboardAberto: null,
		acessoAberto: null,
		cadastroAberto: null,
		precoAberto: null,
		vendaAberto: null,
		logisticaAberto: null,
		estoqueAberto: null,
		metaAberto: null,
		manutencaoAberto: null
	};

	constructor(props) {
		super(props);

		let idInterval = setInterval(() => {
			if (this.props.usuario) {
				this.props.carregarUsuario();
				clearInterval(idInterval);
			}
		}, 2000);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		if (this.props.usuarioCarregado) {
			if (!this.props.usuarioCarregado.acesso && (selecionado == '/usuario')) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.cadastro 
				&& (
					selecionado == '/regiao' || selecionado == '/loja' || selecionado == '/produtoCategoria' || selecionado == '/produto'
				)
			) {
				window.location = '/';
			}

			if (!this.props.usuarioCarregado.pedido_solicitacao 
				&& (
					selecionado == '/'
				)
			) {
				window.location = '/';
			}
		}

		return (
			<nav className="mt-2">
								    
				<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
					{this.props.usuarioCarregado && this.props.usuarioCarregado.acesso ? (
						<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
					) : null}

					{this.props.usuarioCarregado && this.props.usuarioCarregado.cadastro ? (
						<MenuItem path='regiao' label='Região' icon='fas fa-globe' active={selecionado == '/regiao'} />
					) : null}
					{this.props.usuarioCarregado && this.props.usuarioCarregado.cadastro ? (
						<MenuItem path='loja' label='Loja' icon='fas fa-store' active={selecionado == '/loja'} />
					) : null}
					{this.props.usuarioCarregado && this.props.usuarioCarregado.cadastro ? (
						<MenuItem path='produtoCategoria' label='Categoria de Produto' icon='fas fa-tags' active={selecionado == '/produtoCategoria'} />
					) : null}
					{this.props.usuarioCarregado && this.props.usuarioCarregado.cadastro ? (
						<MenuItem path='produto' label='Produto' icon='fas fa-list' active={selecionado == '/produto'} />
					) : null}
					{this.props.usuarioCarregado && this.props.usuarioCarregado.pedido_solicitacao ? (
						<MenuItem path='/' label='Pedido' icon='fas fa-clipboard' active={selecionado == '/'} />
					) : null}

				</ul>
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado
});
const mapDispatchToProps = dispatch => bindActionCreators({ carregarUsuario }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
